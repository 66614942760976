import qs, { stringify } from "query-string";

import { AxiosInstance } from "@bps/http-client";
import { DateTime, getHourAndMinutesFromString } from "@bps/utils";
import {
  MIN_PAGE_ITEMS,
  PAGING_PAGE_NUMBER,
  PAGING_TOTAL_COUNT,
  PAGING_TOTAL_PAGES
} from "@libs/api/types/headers.constants";
import { guid } from "@libs/common/guid";
import { PagingResponse } from "@libs/paging/paging-response.type";
import { withPaging } from "@libs/paging/paging.utils";

import { RefDataDto } from "../../types/common-dtos";
import { IPlatformGateway } from "./platform-gateway.interface";
import {
  AddLicenceDto,
  AddSalesProductDto,
  AddStripeCustomerDto,
  AddSubscriptionDto,
  BillingContactDto,
  BusinessRoleDto,
  CancelCancelCustomerSubscriptionsArgs,
  ComponentActionDto,
  ComponentActionPayload,
  ComponentDefDto,
  ComponentDto,
  ComponentLiteDto,
  ComponentRequestDto,
  ComponentSettingDto,
  ComponentSettingsDto,
  ComponentWorkflowRunInstanceDto,
  CreateComponentPayload,
  CreateSettingPayload,
  CreateTenantDto,
  CustomerProductsDto,
  DeleteSettingArgs,
  Feature,
  FeatureAction,
  FeatureDto,
  GetComponentActionsArgs,
  GetComponentArgs,
  GetComponentsArgs,
  GetComponentsDefArgs,
  GetComponentSettingArgs,
  GetComponentsRequestsArgs,
  GetFeatureParams,
  GetLicencesArgs,
  GetSalesProductsArgs,
  GetTenantFeatureParams,
  InvoiceDto,
  LicenceDto,
  LicenceTypeDto,
  NewPltUser,
  NewTenantTag,
  OrgUnitArgs,
  OrgUnitDto,
  PaymentMethodDto,
  PaymentMethodSetupDto,
  PaymentMethodUpdateArgs,
  PltUser,
  PromotionCodeDto,
  ResetCustomerSubscriptionBillingDateArgs,
  SalesProductDto,
  SecurityRolesAndPermissionsDto,
  StripeCustomerDto,
  SubscriptionDto,
  Tenant,
  TenantDefaultUsersAuthenticationDto,
  TenantDto,
  TenantFeatureDetails,
  TenantFeatureDetailsWrapperDto,
  TenantSearchArgs,
  TenantTag,
  UpdateSettingArgs,
  UpdateSettingPayload,
  UsedTenantTag,
  UserDefaultAuthenticationDto
} from "./plt-gateway.dtos";
import { toTenant } from "./plt-gateway.utils";

const secondsPerDay = 24 * 60 * 60;

export class PlatformGateway implements IPlatformGateway {
  constructor(private api: AxiosInstance) {}

  // Tenant
  async getTenants(args?: TenantSearchArgs): Promise<PagingResponse<Tenant>> {
    const page = args?.pageParam ?? 1;

    const encodedName = btoa(args?.name ?? "");

    const url = qs.stringifyUrl({
      url: "tenants",
      query: withPaging({
        encodedName,
        applications: args?.applications,
        isInactive: args?.isInactive,
        tenantType: args?.tenantType,
        tenantIds: args?.tenantIds,
        featureCodes: args?.featureCodes,
        crmId: args?.crmId,
        crmIds: args?.crmIds,
        countries: args?.countries,
        sortField: args?.sortField,
        sortAscending: args?.sortAscending,
        page: args?.pageParam ?? 1,
        limit: args?.limit ?? MIN_PAGE_ITEMS
      })
    });

    const response = await this.api.get<TenantDto[]>(url);
    const totalPages = +response.headers[PAGING_TOTAL_PAGES];
    const pageNumber = +response.headers[PAGING_PAGE_NUMBER];

    const isMaxPages = pageNumber >= totalPages;

    const total = +response.headers[PAGING_TOTAL_COUNT];

    return {
      results: response.data.map(toTenant),
      next: !isMaxPages ? page + 1 : undefined,
      total,
      totalPages
    };
  }

  async getTenant(id: string): Promise<Tenant> {
    const { data } = await this.api.get<TenantDto>(`tenants/${id}`);
    return toTenant(data);
  }

  async getTenantsByCrmId(crmId: string): Promise<Tenant[]> {
    const url = qs.stringifyUrl({
      url: "tenants",
      query: {
        crmId
      }
    });

    const { data } = await this.api.get<TenantDto[]>(url);

    return data.map(dto => toTenant(dto));
  }

  async getTenantFeature(
    tenantId: string,
    featureCode: string
  ): Promise<TenantFeatureDetails | undefined> {
    const url = `featureManagement/${tenantId}/${featureCode}`;

    try {
      const { data } = await this.api.get<TenantFeatureDetails>(url);
      return data;
    } catch {
      // Workaround to see whether a feature is enabled or not since there isn't
      // an endpoint for a list of features for a tenant
      return undefined;
    }
  }

  async enableDisableFeature(
    tenantId: string,
    featureCode: string,
    action: FeatureAction
  ) {
    await this.api.post("featureManagement", {
      tenantId,
      featureCode,
      isDisabled: action === FeatureAction.Disable
    });
  }

  async encryptTenantResources(tenantId: string) {
    await this.api.put(`tenants/${tenantId}/$encryptResources`);
  }

  async activateTenant(tenantId: string) {
    await this.api.put(`tenants/${tenantId}/$activate`);
  }

  async deactivateTenant(tenantId: string) {
    await this.api.put(`tenants/${tenantId}/$deactivate`);
  }

  updateTenantIsTemporary = async (
    tenantId: string,
    isTemporary: boolean
  ): Promise<Tenant> => {
    return (
      await this.api.put<Tenant>(
        `tenants/${tenantId}/isTemporary/${isTemporary}`
      )
    ).data;
  };

  updateTenantCustomerAccount = async (
    tenantId: string,
    customerTenantId: string
  ): Promise<Tenant> => {
    return (
      await this.api.put<Tenant>(
        `tenants/${tenantId}/customerTenantId/${customerTenantId}`
      )
    ).data;
  };

  updateTenant = async (payload: TenantDto): Promise<Tenant> => {
    const data = (
      await this.api.put<TenantDto>(`tenants/${payload.id}`, payload)
    ).data;

    return toTenant(data);
  };

  createTenant = async (payload: CreateTenantDto): Promise<Tenant> => {
    const data = (await this.api.post<TenantDto>("tenants", payload)).data;

    return toTenant(data);
  };

  async getFeature(params?: GetFeatureParams): Promise<Feature[]> {
    const { data } = await this.api.get<FeatureDto[]>("feature", {
      params
    });
    return data.map(({ changeLog, ...feature }) => ({
      ...feature,
      changeLog: {
        ...changeLog,
        createdDate: changeLog?.createdDate
          ? DateTime.fromISO(changeLog.createdDate)
          : undefined,
        updatedDate: changeLog?.updatedDate
          ? DateTime.fromISO(changeLog.updatedDate)
          : undefined
      }
    }));
  }

  async getSecurityRolesAndPermissions(): Promise<
    SecurityRolesAndPermissionsDto
  > {
    const { data } = await this.api.get<SecurityRolesAndPermissionsDto>(
      "securityRole"
    );
    return data;
  }

  async getBusinessRole(businessRoles?: string[]): Promise<BusinessRoleDto[]> {
    const query = businessRoles?.length
      ? `?${stringify({ businessRoles })}`
      : "";

    const { data } = await this.api.get<BusinessRoleDto[]>(
      `businessRole${query}`
    );
    return data;
  }

  async getTenantFeatures(
    params?: GetTenantFeatureParams
  ): Promise<TenantFeatureDetails[]> {
    const { data } = await this.api.get<TenantFeatureDetailsWrapperDto>(
      "tenantFeature",
      {
        params
      }
    );
    return (
      data.tenantFeatures.map(item => ({
        ...item,
        lastAction: {
          ...item.lastAction,
          timestamp: item.lastAction?.timestamp
            ? DateTime.fromISO(item.lastAction.timestamp)
            : undefined
        },
        changeLog: {
          ...item.changeLog,
          createdDate: item.changeLog?.createdDate
            ? DateTime.fromISO(item.changeLog.createdDate)
            : undefined,
          updatedDate: item.changeLog?.updatedDate
            ? DateTime.fromISO(item.changeLog.updatedDate)
            : undefined
        }
      })) || []
    );
  }

  getTenantDefaultUsersAuthentication = async (tenantId: string) => {
    const { data } = await this.api.get<TenantDefaultUsersAuthenticationDto>(
      `tenants/${tenantId}/userAuthentication/default`
    );
    return {
      ...data,
      sessionMaxDuration:
        data.sessionMaxDuration &&
        Math.ceil(data.sessionMaxDuration / secondsPerDay)
    };
  };

  addTenantDefaultUsersAuthentication = async (
    payload: TenantDefaultUsersAuthenticationDto
  ) => {
    const { data } = await this.api.post<TenantDefaultUsersAuthenticationDto>(
      "tenants/userAuthentication/default",
      {
        ...payload,
        sessionMaxDuration:
          payload.sessionMaxDuration &&
          payload.sessionMaxDuration * secondsPerDay
      }
    );
    return {
      ...data,
      sessionMaxDuration:
        data.sessionMaxDuration &&
        Math.ceil(data.sessionMaxDuration / secondsPerDay)
    };
  };

  updateTenantDefaultUsersAuthentication = async (
    payload: TenantDefaultUsersAuthenticationDto
  ) => {
    const { data } = await this.api.put<TenantDefaultUsersAuthenticationDto>(
      `tenants/${payload.id}/userAuthentication/default`,
      {
        ...payload,
        sessionMaxDuration:
          payload.sessionMaxDuration &&
          payload.sessionMaxDuration * secondsPerDay
      }
    );
    return {
      ...data,
      sessionMaxDuration:
        data.sessionMaxDuration &&
        Math.ceil(data.sessionMaxDuration / secondsPerDay)
    };
  };

  getChildTenants = async (customerTenantId: string) => {
    const query = stringify({ customerTenantId });
    const dto = (
      await this.api.get<TenantDto[]>(`tenants/childTenants/?${query}`)
    ).data;

    return dto.map(toTenant);
  };

  // Users
  async getUser(tenantId: guid, userId: guid): Promise<PltUser> {
    const { data } = await this.api.get<PltUser>(
      `tenants/${tenantId}/users/${userId}`
    );
    return data;
  }

  async searchUsers(
    tenantId: string,
    searchTerm: string,
    bpIdUserId: string
  ): Promise<PltUser[]> {
    const { data } = await this.api.post("users/search", {
      tenantId,
      searchTerm,
      bpIdUserId
    });
    return data || [];
  }

  createUser = async (user: NewPltUser): Promise<PltUser> => {
    const { tenantId, ...rest } = user;
    return (await this.api.post<PltUser>(`tenants/${tenantId}/users`, rest))
      .data;
  };

  updateUser = async (user: PltUser): Promise<PltUser> => {
    return (
      await this.api.put<PltUser>(
        `tenants/${user.tenantId}/users/${user.id}`,
        user
      )
    ).data;
  };

  getUserDefaultAuthentication = async (
    tenantId: string,
    userId: string
  ): Promise<UserDefaultAuthenticationDto> => {
    const { data } = await this.api.get<UserDefaultAuthenticationDto>(
      `tenants/${tenantId}/userAuthentication/users/${userId}`
    );
    return {
      ...data,
      sessionMaxDuration:
        data.sessionMaxDuration &&
        Math.ceil(data.sessionMaxDuration / secondsPerDay)
    };
  };

  addUserDefaultAuthentication = async (
    payload: UserDefaultAuthenticationDto
  ): Promise<UserDefaultAuthenticationDto> => {
    const { data } = await this.api.post<UserDefaultAuthenticationDto>(
      `tenants/${payload.tenantId}/userAuthentication/users/${payload.userId}`,
      {
        ...payload,
        sessionMaxDuration:
          payload.sessionMaxDuration &&
          payload.sessionMaxDuration * secondsPerDay
      }
    );
    return {
      ...data,
      sessionMaxDuration:
        data.sessionMaxDuration &&
        Math.ceil(data.sessionMaxDuration / secondsPerDay)
    };
  };

  updateUserDefaultAuthentication = async (
    payload: UserDefaultAuthenticationDto
  ): Promise<UserDefaultAuthenticationDto> => {
    const { data } = await this.api.put<UserDefaultAuthenticationDto>(
      `tenants/${payload.tenantId}/userAuthentication/users/${payload.userId}`,
      {
        ...payload,
        sessionMaxDuration:
          payload.sessionMaxDuration &&
          payload.sessionMaxDuration * secondsPerDay
      }
    );
    return {
      ...data,
      sessionMaxDuration:
        data.sessionMaxDuration &&
        Math.ceil(data.sessionMaxDuration / secondsPerDay)
    };
  };

  // Licences
  getLicence = async (id: string): Promise<LicenceDto> => {
    return (await this.api.get<LicenceDto>(`licences/${id}`)).data;
  };

  getLicences = (args: GetLicencesArgs): Promise<LicenceDto[]> => {
    const url = qs.stringifyUrl({
      url: "licences",
      query: {
        ...args
      }
    });
    return this.api.get<LicenceDto[]>(url).then(({ data }) => data);
  };

  addLicence = async (data: AddLicenceDto): Promise<LicenceDto> => {
    return (await this.api.post<LicenceDto>("licences", data)).data;
  };

  updateLicence = async (data: LicenceDto): Promise<LicenceDto> => {
    return (await this.api.put<LicenceDto>(`licences/${data.id}`, data)).data;
  };

  removeLicence = async (id: string): Promise<LicenceDto> => {
    return (await this.api.put<LicenceDto>(`licences/${id}/remove`)).data;
  };

  // Licence types
  getLicenceTypes = async (): Promise<LicenceTypeDto[]> => {
    return (await this.api.get<LicenceTypeDto[]>("licenceTypes")).data;
  };

  getLicenceType = async (code: string): Promise<LicenceTypeDto> => {
    return (await this.api.get<LicenceTypeDto>(`licenceTypes/${code}`)).data;
  };

  addLicenceType = async (data: LicenceTypeDto): Promise<LicenceTypeDto> => {
    return (await this.api.post<LicenceTypeDto>("licenceTypes", data)).data;
  };

  updateLicenceType = async (data: LicenceTypeDto): Promise<LicenceTypeDto> => {
    return (
      await this.api.put<LicenceTypeDto>(`licenceTypes/${data.code}`, data)
    ).data;
  };

  disableLicenceType = async (code: string): Promise<void> => {
    return (await this.api.put<void>(`licenceTypes/${code}/disable`)).data;
  };

  enableLicenceType = async (code: string): Promise<void> => {
    return (await this.api.put<void>(`licenceTypes/${code}/enable`)).data;
  };

  // Sales Products
  getSalesProducts = async (
    args?: GetSalesProductsArgs
  ): Promise<SalesProductDto[]> => {
    const url = qs.stringifyUrl({
      url: "sales/product",
      query: {
        licenceTypeCodes: args?.licenceTypeCodes ?? [],
        productCodes: args?.productCodes ?? [],
        productFamilyCodes: args?.productFamilyCodes ?? [],
        applicationCodes: args?.applicationCodes ?? [],
        isInactive: args?.isInactive ?? undefined
      }
    });

    return (await this.api.get<SalesProductDto[]>(url)).data;
  };

  getSalesProduct = async (id: string): Promise<SalesProductDto> => {
    return (await this.api.get<SalesProductDto>(`sales/product/${id}`)).data;
  };

  addSalesProduct = async (
    data: AddSalesProductDto
  ): Promise<SalesProductDto> => {
    return (await this.api.post<SalesProductDto>("sales/product", data)).data;
  };

  updateSalesProduct = async (
    data: SalesProductDto
  ): Promise<SalesProductDto> => {
    return (
      await this.api.put<SalesProductDto>(`sales/product/${data.id}`, data)
    ).data;
  };

  resyncStripeProducts = async (): Promise<void> => {
    await this.api.post("sales/product/stripe/$resync");
  };

  // Billing and payment methods
  getBillingHistory = async (tenantId: string) => {
    return (
      await this.api.get<InvoiceDto[]>(`sales/billing/${tenantId}/history/`)
    ).data;
  };

  getInvoice = async (tenantId: string, invoiceId: string) => {
    return (
      await this.api.get<InvoiceDto>(
        `sales/billing/${tenantId}/invoice/${stringify({
          invoiceId
        })}`
      )
    ).data;
  };

  getBillingContact = async (tenantId: string) => {
    return (
      await this.api.get<BillingContactDto>(
        `sales/billing/${tenantId}/contact/`
      )
    ).data;
  };

  payInvoice = async (tenantId: string, invoiceNumber: string) => {
    return (
      await this.api.post<InvoiceDto>(
        `sales/billing/${tenantId}/invoice/pay/${invoiceNumber}`
      )
    ).data;
  };

  getPaymentMethods = async (customerTenantId: string) => {
    return (
      await this.api.get<PaymentMethodDto[]>(
        `sales/${customerTenantId}/paymentMethods`
      )
    ).data;
  };

  getDefaultPaymentMethod = async (customerTenantId: string) => {
    return (
      await this.api.get<PaymentMethodDto>(
        `sales/${customerTenantId}/paymentMethods/default`
      )
    ).data;
  };

  updatePaymentMethod = async (payload: PaymentMethodUpdateArgs) => {
    const { customerTenantId, id, ...rest } = payload;
    return (
      await this.api.post<PaymentMethodDto>(
        `sales/${customerTenantId}/paymentMethods/${id}`,
        { ...rest }
      )
    ).data;
  };

  setupPaymentMethod = async (customerTenantId: string) => {
    return (
      await this.api.post<PaymentMethodSetupDto>(
        `sales/${customerTenantId}/paymentMethods/setup`
      )
    ).data;
  };

  setupPaymentMethodAsDefault = async (
    customerTenantId: string,
    paymentMethodId: string
  ) => {
    return (
      await this.api.post<PaymentMethodDto>(
        `sales/${customerTenantId}/paymentMethods/${paymentMethodId}/setDefault`
      )
    ).data;
  };

  deletePaymentMethod = async (
    customerTenantId: string,
    paymentMethodId: string
  ) => {
    return (
      await this.api.delete<void>(
        `sales/${customerTenantId}/paymentMethods/${paymentMethodId}`
      )
    ).data;
  };

  // Stripe customer
  getStripeCustomer = async (customerTenantId: string) => {
    return (
      await this.api.get<StripeCustomerDto>(
        `sales/customer/${customerTenantId}`
      )
    ).data;
  };

  addStripeCustomer = async (data: AddStripeCustomerDto) => {
    const time = getHourAndMinutesFromString(data.testClockTime);
    const clockDate = DateTime.fromJSDate(data.testClockDate);
    const clockDateTime = clockDate?.set({
      hour: time.hour,
      minute: time.minute
    });

    const payload: StripeCustomerDto = {
      tenantId: data.tenantId,
      crmId: data.crmId,
      name: data.name,
      country: data.country,
      contact: { email: data.email },
      billingAddress: {
        addressLine1: data.addressLine1,
        addressLine2: data.addressLine2,
        city: data.city,
        state: data.state,
        postcode: data.postcode
      },
      createTestClock: data.useTestClock,
      testClockDate: data.useTestClock
        ? clockDateTime?.setZone("UTC")?.toString()
        : undefined
    };
    return (await this.api.post<StripeCustomerDto>("sales/customer", payload))
      .data;
  };

  getCustomerProducts = async (tenantId: string) => {
    return (
      await this.api.get<CustomerProductsDto>(
        `sales/customer/${tenantId}/products`
      )
    ).data;
  };

  // Subscriptions
  getCustomerSubscriptions = async (customerTenantId: string) => {
    return (
      await this.api.get<SubscriptionDto[]>(
        `sales/subscriptions/${customerTenantId}`
      )
    ).data;
  };

  addCustomerSubscription = async (data: AddSubscriptionDto) => {
    return (await this.api.post<SubscriptionDto>("/sales/subscriptions", data))
      .data;
  };

  addCustomerSubscriptions = async (data: AddSubscriptionDto[]) => {
    return (
      await this.api.post<SubscriptionDto[]>(
        "/sales/subscriptions/$batch",
        data
      )
    ).data;
  };

  cancelCustomerSubscription = async (
    args: CancelCancelCustomerSubscriptionsArgs
  ) => {
    const { customerTenantId, subscriptionId, cancelImmediately } = args;
    const query = cancelImmediately
      ? `?${stringify({ cancelImmediately })}`
      : "";
    return (
      await this.api.post<SubscriptionDto>(
        `/sales/subscriptions/${customerTenantId}/cancel/${subscriptionId}${query}`
      )
    ).data;
  };

  undoCancellationCustomerSubscription = async (
    args: Omit<CancelCancelCustomerSubscriptionsArgs, "cancelImmediately">
  ) => {
    const { customerTenantId, subscriptionId } = args;
    return (
      await this.api.post<SubscriptionDto>(
        `/sales/subscriptions/${customerTenantId}/undoCancellation/${subscriptionId}`
      )
    ).data;
  };

  resetCustomerSubscriptionBillingDate = async (
    args: ResetCustomerSubscriptionBillingDateArgs
  ) => {
    const { customerTenantId, subscriptionId } = args;
    return (
      await this.api.post<SubscriptionDto>(
        `/sales/subscriptions/${customerTenantId}/resetBillingDate/${subscriptionId}`
      )
    ).data;
  };

  // OrgUnit
  async getOrgUnits(args: OrgUnitArgs): Promise<OrgUnitDto[]> {
    const url = qs.stringifyUrl({
      url: "orgUnit",
      query: { tenantId: args.tenantId, hierarchyType: args.hierarchyType }
    });

    const { data } = await this.api.get<OrgUnitDto[]>(url);
    return data;
  }

  // RefData
  getApplicationsRefData = async (): Promise<RefDataDto[]> => {
    return (await this.api.get<RefDataDto[]>("applications")).data;
  };

  getHierarchyTypesRefData = async (): Promise<RefDataDto[]> => {
    return (await this.api.get<RefDataDto[]>("organisationHierarchies")).data;
  };

  async getTenantPromotionCodes(tenantId: string): Promise<PromotionCodeDto[]> {
    return (
      await this.api.get<PromotionCodeDto[]>(
        `sales/promotionCodes/tenant/${tenantId}`
      )
    ).data;
  }

  async getTenantPromotionCode(
    tenantId: string,
    promotionCodeId: string
  ): Promise<PromotionCodeDto> {
    return (
      await this.api.get<PromotionCodeDto>(
        `sales/promotionCodes/${promotionCodeId}/tenant/${tenantId}`
      )
    ).data;
  }

  getComponentsDefs(args?: GetComponentsDefArgs): Promise<ComponentDefDto[]> {
    const url = qs.stringifyUrl({
      url: "componentDefs",
      query: {
        ...args
      }
    });
    return this.api.get<ComponentDefDto[]>(url).then(({ data }) => data || []);
  }

  getComponents(args?: GetComponentsArgs): Promise<ComponentLiteDto[]> {
    const url = qs.stringifyUrl({
      url: "components",
      query: {
        ...args
      }
    });
    return this.api.get<ComponentLiteDto[]>(url).then(({ data }) => data);
  }

  getComponent(args: GetComponentArgs): Promise<ComponentDto> {
    const { codeOrId, ...restQuery } = args;
    const url = qs.stringifyUrl({
      url: `components/${codeOrId}`,
      query: {
        ...restQuery
      }
    });
    return this.api.get<ComponentDto>(url).then(({ data }) => data);
  }

  addOrUpdateComponent = (
    payload: CreateComponentPayload
  ): Promise<ComponentDto> => {
    const { code, tenantId, scopeId, status } = payload;
    const url = qs.stringifyUrl({
      url: `components/${code}`,
      query: {
        tenantId,
        scopeId
      }
    });
    return this.api
      .put<ComponentDto>(url, { status })
      .then(({ data }) => data);
  };

  archiveComponent = (
    payload: ComponentActionPayload
  ): Promise<ComponentDto> => {
    const { code, tenantId, scopeId } = payload;
    const url = qs.stringifyUrl({
      url: `components/${code}/$archive`,
      query: {
        tenantId,
        scopeId
      }
    });
    return this.api.post<ComponentDto>(url, {}).then(({ data }) => data);
  };

  reConfigureComponent = (
    payload: ComponentActionPayload
  ): Promise<ComponentDto> => {
    const { code, tenantId, scopeId } = payload;
    const url = qs.stringifyUrl({
      url: `components/${code}/$reConfigure`,
      query: {
        tenantId,
        scopeId
      }
    });
    return this.api.post<ComponentDto>(url, {}).then(({ data }) => data);
  };

  initializeComponent = (
    payload: ComponentActionPayload
  ): Promise<ComponentDto> => {
    const { code, tenantId, scopeId } = payload;
    const url = qs.stringifyUrl({
      url: `components/${code}/$initialize`,
      query: {
        tenantId,
        scopeId
      }
    });
    return this.api.post<ComponentDto>(url, {}).then(({ data }) => data);
  };

  deInitializeComponent = (
    payload: ComponentActionPayload
  ): Promise<ComponentDto> => {
    const { code, tenantId, scopeId } = payload;
    const url = qs.stringifyUrl({
      url: `components/${code}/$deInitialize`,
      query: {
        tenantId,
        scopeId
      }
    });
    return this.api.post<ComponentDto>(url, {}).then(({ data }) => data);
  };

  disableComponent = (
    payload: ComponentActionPayload
  ): Promise<ComponentDto> => {
    const { code, tenantId, scopeId } = payload;
    const url = qs.stringifyUrl({
      url: `components/${code}/$disable`,
      query: {
        tenantId,
        scopeId
      }
    });
    return this.api.post<ComponentDto>(url, {}).then(({ data }) => data);
  };

  getComponentActions(
    args: GetComponentActionsArgs
  ): Promise<ComponentActionDto[]> {
    const url = qs.stringifyUrl({
      url: "components/actions",
      query: {
        ...args
      }
    });
    return this.api.get<ComponentActionDto[]>(url).then(({ data }) => data);
  }

  getComponentAction(componentId: string): Promise<ComponentActionDto> {
    const url = qs.stringifyUrl({
      url: `components/actions/${componentId}`
    });
    return this.api.get<ComponentActionDto>(url).then(({ data }) => data);
  }

  getComponentSettings(
    args: ComponentActionPayload
  ): Promise<ComponentSettingsDto> {
    const { code, ...queryRest } = args;
    const url = qs.stringifyUrl({
      url: `components/${code}/settings`,
      query: { ...queryRest }
    });
    return this.api.get<ComponentSettingsDto>(url).then(({ data }) => data);
  }

  getComponentSetting(
    args: GetComponentSettingArgs
  ): Promise<ComponentSettingDto> {
    const { code, key, ...queryRest } = args;
    const url = qs.stringifyUrl({
      url: `components/${code}/settings/${key}`,
      query: { ...queryRest }
    });
    return this.api.get<ComponentSettingDto>(url).then(({ data }) => data);
  }

  createComponentSettings(
    args: ComponentActionPayload,
    payload: CreateSettingPayload
  ): Promise<
    Omit<ComponentDto, "implementations" | "id" | "eTag" | "changeLog">
  > {
    const { code, ...queryRest } = args;
    const url = qs.stringifyUrl({
      url: `components/${code}/settings/`,
      query: { ...queryRest }
    });

    return this.api
      .post<
        Omit<ComponentDto, "implementations" | "id" | "eTag" | "changeLog">
      >(url, payload)
      .then(({ data }) => data);
  }

  updateComponentSettings(
    args: UpdateSettingArgs,
    payload: UpdateSettingPayload
  ): Promise<ComponentSettingDto> {
    const { code, ...queryRest } = args;
    const { key, ...restPayload } = payload;
    const url = qs.stringifyUrl({
      url: `components/${code}/settings/${key}`,
      query: { ...queryRest }
    });

    return this.api
      .put<ComponentSettingDto>(url, restPayload)
      .then(({ data }) => data);
  }

  deleteComponentSettings(args: DeleteSettingArgs): Promise<void> {
    const { code, key, ...queryRest } = args;
    const url = qs.stringifyUrl({
      url: `components/${code}/settings/${key}`,
      query: { ...queryRest }
    });

    return this.api.delete<void>(url).then(({ data }) => data);
  }

  getComponentsRequests(
    args: GetComponentsRequestsArgs
  ): Promise<ComponentRequestDto[]> {
    const url = qs.stringifyUrl({
      url: "components/actionRequests",
      query: { ...args }
    });
    return this.api.get<ComponentRequestDto[]>(url).then(({ data }) => data);
  }

  getComponentRequests(
    componentActionRequestId: string
  ): Promise<ComponentRequestDto> {
    return this.api
      .get<ComponentRequestDto>(
        `components/actionRequests/${componentActionRequestId}`
      )
      .then(({ data }) => data);
  }

  getComponentWorkflowRunInstance(
    id: string
  ): Promise<ComponentWorkflowRunInstanceDto> {
    return this.api
      .get<ComponentWorkflowRunInstanceDto>(
        `components/componentWorkflowInstances/${id}`
      )
      .then(({ data }) => data);
  }

  getComponentManagerWorkflowRunInstance(
    id: string
  ): Promise<ComponentWorkflowRunInstanceDto> {
    return this.api
      .get<ComponentWorkflowRunInstanceDto>(
        `components/componentManagerWorkflowInstances/${id}`
      )
      .then(({ data }) => data);
  }

  getTenantTags = async (tenantId: string): Promise<TenantTag[]> => {
    return this.api
      .post<TenantTag[]>(`tenants/tags/tenant/${tenantId}`)
      .then(({ data }) => data);
  };

  addTenantTag = async (tag: NewTenantTag): Promise<TenantTag> => {
    return (await this.api.post<TenantTag>("tenants/tags", tag)).data;
  };

  updateTenantTag = async (tag: TenantTag): Promise<TenantTag> => {
    return (await this.api.put<TenantTag>(`tenants/tags/${tag.id}`, tag)).data;
  };

  deleteTenantTag = async (tag: TenantTag): Promise<TenantTag> => {
    await this.api.delete<TenantTag>(`tenants/tags/${tag.id}`);
    return tag;
  };

  getUsedTenantTags = async (): Promise<UsedTenantTag[]> => {
    return this.api
      .post<UsedTenantTag[]>("tenants/tags/used")
      .then(({ data }) => data);
  };
}
