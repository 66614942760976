import { DateTime } from "@bps/utils";
import {
  Rollout,
  SecurityRole
} from "@libs/api/gateways/field/field-ops-gateway.dtos";
import { ChangeLogDto } from "@libs/api/types/common-dtos";
import { guid } from "@libs/common/guid";

export enum ApplicationType {
  CAM = "CAM",
  OMNI = "TI",
  PROS = "PR",
  BPID = "BPID"
}

// Source - Platform Catalog BpId.CloudApplications table
export enum ApplicationName {
  BPID = "Bp User Account",
  CAM = "Bp Business Account",
  PARTNER = "Bp Partner Documentation",
  DEVEL = "Bp Developer Portal",
  FORUM = "Bp User Forum",
  EXTERNAL = "Third Party Application",
  MERC = "Bp Online Teams",
  TI = "Bp Omni",
  PR = "Bp Premier Online"
}

export function getApplicationNameFromAppCode(appCode: string | undefined) {
  const internalAppCode = appCode as ApplicationType;

  switch (internalAppCode) {
    case ApplicationType.CAM:
      return ApplicationName.CAM as string;
    case ApplicationType.PROS:
      return ApplicationName.PR as string;
    case ApplicationType.OMNI:
      return ApplicationName.TI as string;
  }

  return "Unknown Application";
}

export interface TenantSearchArgs {
  name?: string;
  tenantType?: string;
  tenantIds?: string[];
  featureCodes?: string[];
  countries?: string[];
  crmId?: string;
  crmIds?: string[];
  applications?: ApplicationType[];
  isInactive?: boolean;
  // sorting arguments
  sortField?:
    | "Name"
    | "ApplicationCode"
    | "Country"
    | "UpdatedDate"
    | "IsInactive"
    | "IsTemporary";
  sortAscending?: boolean;
  // paging arguments
  limit?: number;
  pageParam?: number;
}

export interface Permission {
  id: guid;
  code: string;
  text: string;
  isActive: boolean;
  source: string;
}

export interface SecurityRolesAndPermissionsDto {
  permissions: Permission[];
  securityRoles: SecurityRole[];
}

export interface ProfileDto {
  businessRoleCode?: string;
  countryCode?: Country;
  defaultSecurityRoleCodes?: string[];
  requiredComponentCodes?: string[];
  requiredLicenceTypeCodes?: string[];
}

export interface BusinessRoleDto {
  code?: string;
  text?: string;
  classCode?: string;
  profiles?: ProfileDto[];
  minMembersCount?: number;
  maxMembersCount?: number;
}

export interface Tenant {
  id: guid;
  eTag: string;
  name: string;
  country: Country;
  permissions: string[];
  application: ApplicationType;
  customerTenantId?: guid;
  stripeCustomerId?: guid;
  crmId?: string;
  userLimit: number;
  changeLog: TenantChangeLog;
  resources: TenantResource[];
  tags: TenantTag[];
  childTenants?: TenantDto[];
  isInactive: boolean;
  isTemporary: boolean;
  siteAuthorityFieldDeviceId?: string;
}

export interface TenantChangeLog {
  createdBy: string;
  createdDate: DateTime;
  updatedBy?: string;
  updatedDate?: DateTime;
}

export interface TenantResource {
  id: guid;
  tenantId: guid;
  azResourceType: string;
  bpResourceName: string;
  connectionString: string;
  encryptionKey: string;
}

export interface TenantTag {
  id: guid;
  tenantId: guid;
  tag: string;
}

export interface NewTenantTag extends Omit<TenantTag, "id"> {}

export interface UsedTenantTag extends Omit<TenantTag, "id" | "tenantId"> {}

export type TenantDto = Omit<Tenant, "changeLog"> & {
  changeLog: TenantChangeLogDto;
};
type TenantChangeLogDto = Omit<
  TenantChangeLog,
  "createdDate" | "updatedDate"
> & {
  createdDate: string;
  updatedDate: string | undefined;
};

export type CreateTenantDto = {
  name: string;
  country: Country;
  application: string;
  customerTenantId?: string;
  userLimit: number;
  tenantType: string;
  isInactive: boolean;
};

export interface GetFeatureParams {
  source?: string;
  isActive?: boolean;
}

export interface GetTenantFeatureParams {
  tenantIds?: string;
  featureCodes?: string[];
}

export interface TenantFeatureDetailsWrapperDto {
  tenantFeatures: TenantFeatureDetailsDto[];
}

export interface TenantFeatureDetails {
  id: string;
  tenantId: string;
  feature: string;
  lastActionId: string;
  lastAction: {
    id: string;
    tenantId: string;
    orchestrationId: string;
    feature: string;
    action: string;
    status: string;
    timestamp?: DateTime;
  };
  changeLog: {
    createdDate?: DateTime;
    createdBy?: string;
    updatedDate?: DateTime;
    updatedBy?: string;
  };
}

export interface TenantFeatureDetailsDto
  extends Omit<TenantFeatureDetails, "changeLog" | "lastAction"> {
  lastAction: Omit<TenantFeatureDetails["lastAction"], "timestamp"> & {
    timestamp: string;
  };
  changeLog: ChangeLogDto;
}

export interface FeatureDto extends Omit<Feature, "changeLog"> {
  changeLog: ChangeLogDto;
}

export interface LicenceTypeDto {
  code: string;
  text: string;
  countryCode: Country;
  source?: string;
  isInactive: boolean;
  gracePeriod?: number;
  sortOrder?: number;
}

export interface LicenceDto {
  id: string;
  tenantId: string;
  productId?: string;
  userId?: string;
  username?: string;
  expiryDate: string;
  doNotRenew: boolean;
  isInactive: boolean;
  licenceTypeCode?: string;
  name?: string;
  displayName?: string;
  description?: string;
  source?: string;
  changeLog: ChangeLogDto;
}

export interface GetLicencesArgs {
  tenantId?: string;
  userIds?: string[];
  licenceType?: string;
  isExpired?: boolean;
  doNotRenew?: boolean;
  isInactive?: boolean;
}

export interface SalesProductDto {
  id: string;
  sourceProductId?: string;
  name?: string;
  source?: string;
  displayName?: string;
  description?: string;
  isInactive: boolean;
  licenceType?: string;
  productCode?: string;
  applicationCode?: ApplicationType;
  productFamily?: string;
  iconName?: string;
  interval?: string;
  country?: string;
  components?: string[];
  changeLog: ChangeLogDto;
}

export interface GetSalesProductsArgs {
  productCodes?: string[];
  productFamilyCodes?: string[];
  applicationCodes?: string[];
  licenceTypeCodes?: string[];
  isInactive?: boolean;
  country?: string;
}

export type IpRanges = { ipRangeBegin: string; ipRangeEnd: string };

export interface TenantDefaultUsersAuthenticationDto {
  id: string;
  sessionMaxDuration?: number;
  deviceMfaMaxDuration?: number;
  longPasswordRequired?: boolean;
  requireAlternateEmailAuthenticationMethod?: boolean;
  requireAlternateEmailAuthenticationStrength?: boolean;
  requirePhoneAuthenticationMethod?: boolean;
  requirePhoneAuthenticationStrength?: boolean;
  requireTotpAuthenticationMethod?: boolean;
  requireTotpAuthenticationStrength?: boolean;
  quickPinRequired?: boolean;
  ipRanges?: IpRanges[];
}

export interface UserDefaultAuthenticationDto
  extends Omit<TenantDefaultUsersAuthenticationDto, "id"> {
  userId: string;
  tenantId: string;
}

export interface AddLicenceDto extends Omit<LicenceDto, "id" | "changeLog"> {}

export interface AddSalesProductDto
  extends Omit<SalesProductDto, "id" | "changeLog"> {}

export enum CardBrand {
  Visa = "visa",
  Master = "mastercard"
}

export enum PaymentType {
  Card = "card",
  Becs = "au_becs_debit"
}

export const CardBrandLabels = {
  [CardBrand.Visa]: "Visa",
  [CardBrand.Master]: "Master Card"
};

export interface PaymentMethodDto {
  id: string;
  type: PaymentType;
  brand: CardBrand;
  bsbNumber: string;
  expMonth: number;
  expYear: number;
  last4: string;
  isExpired: boolean;
  isDefault?: boolean;
}

export interface PaymentMethodSetupDto {
  id: string;
  clientSecret: string;
}

export interface PaymentMethodUpdateArgs {
  id: string;
  customerTenantId: string;
  expMonth: number;
  expYear: number;
}

export interface BillingContactAddressDto {
  line1?: string;
  line2?: string;
  country?: string;
  state?: string;
  postCode?: string;
  city?: string;
}

export interface BillingContactDto {
  name?: string;
  phone?: string;
  email?: string;
  address?: BillingContactAddressDto;
}

export enum InvoiceStatus {
  Draft = "draft", // The starting status for all invoices. You can still edit the invoice at this point.
  Open = "open", // The invoice has been finalized, and is awaiting customer payment. You can no longer edit the invoice, but you can revise it.
  Paid = "paid", //This invoice was paid.
  Void = "void", //This invoice was a mistake, and must be canceled.
  Uncollectible = "uncollectible" // The customer is unlikely to pay this invoice (treat it as bad debt in your accounting process).
}

export interface InvoiceDto {
  id: string;
  number: string;
  status: InvoiceStatus;
  subtotal?: number;
  total?: number;
  tax?: number;
  payment_date?: string;
  payment_method?: string;
  description?: string;
  created?: string;
  hostedInvoiceUrl?: string;
  invoicePdf?: string;
  errorMessage?: string;
}

export enum Country {
  Australia = "AU",
  NewZealand = "NZ"
}

export enum CountryText {
  AU = "Australia",
  NZ = "New Zealand"
}

export interface StripeCustomerContact {
  name: string;
  email: string;
  phone: string;
}

export interface StripeCustomerAddress {
  name: string;
  addressLine1: string;
  addressLine2: string;
  postcode: string;
  city: string;
  state: string;
}

export interface StripeCustomerDto {
  stripeCustomerId?: string;
  tenantId: string;
  crmId?: string;
  name?: string;
  description?: string;
  country?: Country;
  contact?: Partial<StripeCustomerContact>;
  billingContact?: Partial<StripeCustomerContact>;
  billingAddress?: Partial<StripeCustomerAddress>;
  shippingAddress?: Partial<StripeCustomerAddress>;
  createTestClock?: boolean;
  testClockDate?: string;
}

export interface AddStripeCustomerDto
  extends Partial<Omit<StripeCustomerAddress, "name">> {
  country: Country;
  tenantId: string;
  email: string;
  name: string;
  crmId: string;
  useTestClock?: boolean;
  testClockDate?: Date;
  testClockTime?: string;
}

export interface ChildTenantItem {
  salesProductId: string;
  salesProductFamily?: string;
  salesProductCode?: string;
  salesProductInterval?: string;
  quantity: number;
  scheduledQuantity: number;
  scheduleDate: string;
}

export interface ChildTenant {
  childTenantId: string;
  items: ChildTenantItem[];
}

export interface AddSubscriptionDto extends Omit<ChildTenant, "items"> {
  promotionCodeList?: string[];
  items: Pick<ChildTenantItem, "quantity" | "salesProductId">[];
}

export interface CancelCancelCustomerSubscriptionsArgs {
  customerTenantId: string;
  subscriptionId: string;
  cancelImmediately?: boolean;
}

export interface ResetCustomerSubscriptionBillingDateArgs {
  customerTenantId: string;
  subscriptionId: string;
}

export enum SubscriptionStatuses {
  Incomplete = "incomplete",
  IncompleteExpired = "incomplete_expired",
  Trialing = "trialing",
  Active = "active",
  PastDue = "past_due",
  Canceled = "canceled",
  Unpaid = "unpaid"
}

export interface SubscriptionDto {
  customerTenantId: string;
  subscriptionId: string;
  status: SubscriptionStatuses;
  schedule?: string;
  description?: string;
  created?: string;
  currentPeriodStart?: string;
  currentPeriodEnd?: string;
  latestInvoiceId?: string;
  hostedInvoiceUrl?: string;
  invoicePdf?: string;
  childTenants: ChildTenant[];
  cancelAt?: string;
  cancelAtPeriodEnd?: boolean;
  cancelRequestDate?: string;
  promotionCodeList?: PromotionCodeDto[];
  coupon?: CouponDto;
}

export interface CustomerProductsDto {
  customerTenantId: string;
  customerTenantName: string;
  stripeCustomerId: string;
  stripeCustomerName: string;
  defaultPaymentMethodId: string;
  defaultPaymentMethodBrand: string;
  defaultPaymentMethodLast4: string;
  defaultPaymentMethodExpMonth: number;
  defaultPaymentMethodExpYear: number;
  taxType: string;
  taxPercentage: number;

  subscriptionList: CustomerProductsSubscription[];
  childTenantList: CustomerProductsChildTenant[];
}

export interface CustomerProductsSubscription {
  subscriptionId: string;
  type: string;
  status: SubscriptionStatuses;
  renewalDate: string;
  licenceCount: number;
  userCount: number;
  description?: string;
  promotionCodeList?: string[];
  siteCount: number;
  totalExcludingTax: number;
  tax: number;
  total: number;
  createdDate: string;
  currentPeriodStart: string;
  currentPeriodEnd: string;
  cancellationDate: string;
  cancelAtPeriodEnd: boolean;
  latestInvoiceId?: string;
  schedule?: string;
  subscriptionItemList: CustomerProductsSubscriptionItem[];
}

export interface CustomerProductsSubscriptionItem {
  childTenantId: string;
  childTenantName: string;
  applicationCode: ApplicationType;
  productId: string;
  productFamily: string;
  productCode: string;
  name: string;
  displayName: string;
  description: string;
  iconName: string;
  licenceType: string;
  sourceProductId: string;
  quantity: number;
  scheduleDate: string;
  scheduledQuantity: number;
  licenceCount: number;
  userCount: number;
  amount: number;
  amountIncludesTax: boolean;
}

export interface CustomerProductsChildTenant {
  childTenantId: string;
  childTenantName: string;
  applicationCode: ApplicationType;
  productFamilyList: CustomerProductsProductFamily[];
}

export interface CustomerProductsProductFamily {
  productFamily: string;
  productList: CustomerProductsItem[];
  subscriptionList: CustomerProductsSubscription[];
}

export interface CustomerProductsItem
  extends Pick<
    CustomerProductsSubscriptionItem,
    | "sourceProductId"
    | "productCode"
    | "name"
    | "displayName"
    | "iconName"
    | "description"
    | "licenceType"
    | "amount"
    | "amountIncludesTax"
  > {
  salesProductId: string;
}

export interface OrgUnitArgs {
  tenantId: string;
  hierarchyType?: string;
}

export interface OrgUnitDto {
  id: string;
  tenantId: string;
  name: string;
  hierarchyType: string;
  addresses: AddressDto[];
  contact: ContactDto[];
  timeZone: string;
  tagLine: string;
  parentOrgUnitId: string;
  isInactive: string;
  eTag: string;
  changeLog: ChangeLogDto;
}

export interface AddressDto {
  type?: string;
  street1?: string;
  street2?: string;
  suburb?: string;
  city?: string;
  state?: string;
  postCode?: string;
  country?: string;
}

export interface ContactDto {
  type?: string;
  value?: string;
  preferred?: string;
}

export interface SubscriptionProductsDto
  extends Omit<SubscriptionDto, "childTenants"> {
  childTenants: (Omit<ChildTenant, "items"> & {
    items: (ChildTenantItem & Partial<Omit<SalesProductDto, "id">>)[];
    tenantName?: string;
    crmId?: string;
  })[];
}

export interface RolloutDto
  extends Omit<
    Rollout,
    | "initialDownloadStartUtc"
    | "initialDownloadEndUtc"
    | "initialInstallStartUtc"
    | "initialInstallEndUtc"
  > {
  initialDownloadStartUtc: string;
  initialDownloadEndUtc: string;
  initialInstallStartUtc: string;
  initialInstallEndUtc: string;
}

export interface RolloutArgsDto {
  deploymentRingId?: string;
}

export function mapRolloutFromDto(rollout: RolloutDto) {
  return {
    ...rollout,
    initialDownloadStartUtc: DateTime.fromISO(
      rollout.initialDownloadStartUtc
    ).setZone("utc"),
    initialDownloadEndUtc: DateTime.fromISO(
      rollout.initialDownloadEndUtc
    ).setZone("utc"),
    initialInstallStartUtc: DateTime.fromISO(
      rollout.initialInstallStartUtc
    ).setZone("utc"),
    initialInstallEndUtc: DateTime.fromISO(
      rollout.initialInstallEndUtc
    ).setZone("utc")
  };
}

export interface CouponDto {
  id: string;
  amountOff: number;
  created: string;
  currency: string;
  deleted: boolean;
  duration: string;
  durationInMonths: number;
  maxRedemptions: number;
  name: string;
  percentOff: number;
  redeemBy: string;
  timesRedeemed: number;
  valid: boolean;
}

export interface PromotionCodeDto {
  id: string;
  active: boolean;
  code: string;
  created: string;
  expires_at: string;
  max_redemptions: number;
  timesRedeemed: number;
  coupon: CouponDto;
}

export enum BaseUserStatuses {
  active = "ACTIVE",
  inactive = "INACTIVE"
}

export interface PltUserSecurityRole {
  code?: string;
  securityRoleId?: string;
}

export interface PltUser {
  id: string;
  tenantId: string;
  username: string;
  externalId?: number;
  status?: BaseUserStatuses;
  permissions: string[];
  roles: { roleId: string; code: string; isSystemManaged?: boolean }[];
  businessRoles: string[];
  securityRoles: PltUserSecurityRole[];
  businessRolesClasses: string[];
  loginAttempts?: number;
  lastLoginAttempt?: DateTime;
  title: string;
  firstName?: string;
  lastName?: string;
  isInactive?: boolean;
  bpIdUserId?: string;
  crmContactId?: string;
  changeLog: PltUserChangeLog;
}

export interface NewPltUser
  extends Pick<
    PltUser,
    "firstName" | "lastName" | "tenantId" | "status" | "crmContactId"
  > {
  username?: string;
}

export interface PltUserChangeLog {
  createdBy: string;
  createdDate: DateTime;
  updatedBy?: string;
  updatedDate?: DateTime;
}

export interface Feature {
  source: string;
  featureDependencies: Array<{
    source: string;
    featureCode: string;
    dependentFeatureCode: string;
  }>;
  featurePermissions: Array<{
    source: string;
    featureCode: string;
    permissionCode: string;
  }>;
  id: guid;
  code: string;
  text: string;
  description: string;
  isSystemManaged: boolean;
  isRemovable: boolean;
  licenceCode: string;
  hasActionProcessor: boolean;
  changeLog: {
    createdDate?: DateTime;
    createdBy?: string;
    updatedDate?: DateTime;
    updatedBy?: string;
  };
  dependencies: Array<string>;
  permissions: Array<string>;
}

export enum TenantFeature {
  AvailabilityCalculator = "PROS-PROVIDER-AVAILABILITY-CALC",
  BhbIntegration = "PROS-BHB-INTEGRATION",
  AvailabilityCalculatorOnSite = "PROS-PROVIDER-AVAILABILITY-CALC-ON-SITE",
  TenantReplicationMessageIsolation = "PROS-TENANT-REPLICATION-MESSAGE-ISOLATION"
}

export enum FeatureAction {
  Enable = "ENABLE",
  Disable = "DISABLE"
}

export enum FeatureStatus {
  Initializing = "INITIALIZING",
  Completed = "COMPLETED",
  Unavailable = "UNAVAILABLE",
  Failed = "FAILED"
}

export interface GetComponentsDefArgs {
  componentCode?: string;
  source?: string;
  scope?: string;
}

export interface ComponentDefSettingDto {
  componentCode: string;
  key: string;
  isRequired: boolean;
}

export interface ComponentDefDto {
  code: string;
  text: string;
  isActive: boolean;
  scope: string;
  implementationsLimit: string;
  source: string;
  implementations: string[];
  dependencies: string[];
  settings: ComponentDefSettingDto[];
}

export interface GetComponentsArgs {
  code?: string;
  scopeId?: string;
  tenantId?: string;
  status?: string;
}

export interface GetComponentArgs
  extends Pick<GetComponentsArgs, "scopeId" | "tenantId"> {
  codeOrId: string;
}

export enum ComponentStatus {
  Enabled = "Enabled",
  Disabled = "Disabled",
  RequiresConfig = "RequiresConfig",
  Configuring = "Configuring"
}

export interface ComponentLiteDto {
  id: string;
  tenantId: string;
  code: string;
  scopeId: string;
  status: ComponentStatus;
  eTag: string;
  changeLog: ChangeLogDto;
}

export interface ComponentImplementationDto {
  code: string;
  status: string;
}

export interface ComponentSettingLiteDto {
  key: string;
  value: string;
  desiredValue?: string;
}

export interface ComponentSettingDto extends ComponentSettingLiteDto {
  id: string;
  tenantId: string;
  code: string;
  scopeId: string;
  changeLog: ChangeLogDto;
}

export interface ComponentSettingsDto extends ComponentSettingLiteDto {
  tenantId: string;
  code: string;
  scopeId: string;
  settings: ComponentSettingLiteDto[];
}

export interface CreateSettingPayload {
  settings: ComponentSettingLiteDto[];
}

export interface UpdateSettingArgs extends ComponentActionPayload {}
export interface DeleteSettingArgs extends GetComponentSettingArgs {}

export interface GetComponentSettingArgs extends ComponentActionPayload {
  key: string;
}

export interface UpdateSettingPayload
  extends Omit<ComponentSettingLiteDto, "desiredValue"> {}

export interface ComponentDto extends ComponentLiteDto {
  implementations?: ComponentImplementationDto[];
  settings?: ComponentSettingLiteDto[];
}

export interface ComponentActionDto {
  id: string;
  componentId: string;
  action: string;
  status: ComponentStatus;
  componentActionRequestId: string;
  componentWorkflowRunInstanceId: string;
  changeLog: ChangeLogDto;
}

export interface CreateComponentPayload {
  code: string;
  tenantId: string;
  scopeId?: string;
  status?: string;
}

export interface ComponentActionPayload {
  code: string;
  tenantId?: string;
  scopeId?: string;
}

export interface GetComponentActionsArgs {
  componentId?: string;
  componentActionRequestId?: string;
  componentAction?: ComponentRequestActions;
  status?: ComponentRequestStatuses;
  componentWorkflowRunInstanceId?: string;
}

export interface GetComponentsRequestsArgs
  extends Pick<
    GetComponentActionsArgs,
    "status" | "componentWorkflowRunInstanceId" | "componentAction"
  > {
  tenantId?: string;
  scopeId?: string;
  componentCode?: string;
}

export enum ComponentRequestStatuses {
  InProgress = "InProgress",
  Succeeded = "Succeeded",
  Failed = "Failed"
}

export enum ComponentRequestActions {
  Initialize = "Initialize",
  Disable = "Disable",
  ReConfigure = "ReConfigure",
  Archive = "Archive",
  Deinitialize = "Deinitialize"
}

export interface ComponentRequestDto {
  id: string;
  code: string;
  tenantId: string;
  scopeId: string;
  action: ComponentRequestActions;
  status: ComponentRequestStatuses;
  statusReason: string;
  componentManagerWorkflowRunInstanceId: string;
  changeLog: ChangeLogDto;
}

export interface ComponentWorkflowRunInstanceDto {
  workflowInstanceUrl: string | undefined;
}
